var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "chat-details" }, [
    _c("div", { staticClass: "box" }, [
      _vm._m(0),
      _c("div", { staticClass: "box-bottom" }, [
        _c("div", { staticClass: "tips" }, [
          _vm._v("擅长：乳腺疾病、肺部疾病")
        ]),
        _c("div", [
          _c("div", { staticClass: "title" }, [_vm._v("个人信息")]),
          _vm._m(1),
          _vm._m(2),
          _c("div", { staticClass: "list" }, [
            _vm._v(" 评分"),
            _c(
              "span",
              [
                _c("van-icon", { attrs: { name: "star", color: "#FF5000" } }),
                _c("van-icon", { attrs: { name: "star", color: "#FF5000" } }),
                _c("van-icon", { attrs: { name: "star", color: "#FF5000" } }),
                _c("van-icon", { attrs: { name: "star", color: "#FF5000" } }),
                _c("van-icon", { attrs: { name: "star", color: "#FF5000" } })
              ],
              1
            )
          ])
        ])
      ]),
      _c("div", { staticClass: "kong" }),
      _c("div", { staticClass: "kong1" })
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "box-top" }, [
      _c("img", {
        attrs: { src: require("./../../assets/images/service.png") }
      })
    ])
  },
  function() {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "list" }, [
      _vm._v(" 姓名"),
      _c("span", [_vm._v("一保保")])
    ])
  },
  function() {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "list" }, [
      _vm._v(" 性别"),
      _c("span", [_vm._v("保密")])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }