<template>
  <div class="chat-details">
      <div class="box">
        <div class="box-top"><img src="./../../assets/images/service.png" /></div>
        <div class="box-bottom">
          <div class="tips">擅长：乳腺疾病、肺部疾病</div>
          <div>
            <div class="title">个人信息</div>
            <div class="list">
              姓名<span>一保保</span>
            </div>
            <div class="list">
              性别<span>保密</span>
            </div>
            <div class="list">
              评分<span>
              <van-icon name="star" color="#FF5000" />
              <van-icon name="star" color="#FF5000" />
              <van-icon name="star" color="#FF5000" />
              <van-icon name="star" color="#FF5000" />
              <van-icon name="star" color="#FF5000" />
            </span>
            </div>
          </div>
        </div>
        <div class="kong"></div>
        <div class="kong1"></div>
      </div>
  </div>
</template>

<script>
export default {
  name: 'chatDetails',
  data() {
    return {

    }
  },
   components: {

   },
  props: {

  },
  created() {

  },
   mounted() {

  },
  methods: {


  },
   destroyed () {

    },

  updated(){

  },
}
</script>

<style lang="scss" scoped>
  .chat-details{
    width: 100%;
    height: 100%;
    background: url("../../assets/images/bg.png") no-repeat;
    background-size: 100% 100%;
    position: fixed;
    top: 0;
    left: 0;
    .box{
      width: 6.3rem;
      position: absolute;
      top: 1.56rem;
      left: .6rem;
      height: 8rem;
      .box-top{
        width: 100%;
        text-align: center;
        img{
          width: 2rem;
          height: 2rem;
        }
      }
      .box-bottom{
        width: 100%;
        background: #fff;
        border-radius: .16rem;
        padding: .4rem;
        font-size: .28rem;
        padding-top: 1.4rem;
        position: absolute;
        top:1rem;
        z-index: -1;
        .tips{
          color: #131313;
          margin-bottom: 1rem;
          text-align: center;
        }
        .title{
          font-weight: 500;
          color: #131313;
          margin-bottom: .6rem;
        }
        .list{
          color: #C4C4C4;
          margin-bottom: .4rem;
          span{
            margin-left: .6rem;
            color: #131313;
          }
        }
      }
      .kong{
        width: 5.1rem;
        height: .4rem;
        background: rgba(255,255,255,0.5);
        position: absolute;
        bottom: 0;
        border-radius: .08rem;
        left:0.6rem;
      }
      .kong1{
        width: 5.7rem;
        height: .3rem;
        background: rgba(255,255,255,0.5);
        position: absolute;
        bottom: 0.1rem;
        border-radius: .08rem;
        left:0.3rem;
      }
    }
  }
</style>

