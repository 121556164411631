// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/_css-loader@3.6.0@css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../node_modules/_css-loader@3.6.0@css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../../assets/images/bg.png");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
exports.push([module.id, ".chat-details[data-v-5f94b50c] {\n  width: 100%;\n  height: 100%;\n  background: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") no-repeat;\n  background-size: 100% 100%;\n  position: fixed;\n  top: 0;\n  left: 0;\n}\n.chat-details .box[data-v-5f94b50c] {\n    width: 6.3rem;\n    position: absolute;\n    top: 1.56rem;\n    left: .6rem;\n    height: 8rem;\n}\n.chat-details .box .box-top[data-v-5f94b50c] {\n      width: 100%;\n      text-align: center;\n}\n.chat-details .box .box-top img[data-v-5f94b50c] {\n        width: 2rem;\n        height: 2rem;\n}\n.chat-details .box .box-bottom[data-v-5f94b50c] {\n      width: 100%;\n      background: #fff;\n      border-radius: .16rem;\n      padding: .4rem;\n      font-size: .28rem;\n      padding-top: 1.4rem;\n      position: absolute;\n      top: 1rem;\n      z-index: -1;\n}\n.chat-details .box .box-bottom .tips[data-v-5f94b50c] {\n        color: #131313;\n        margin-bottom: 1rem;\n        text-align: center;\n}\n.chat-details .box .box-bottom .title[data-v-5f94b50c] {\n        font-weight: 500;\n        color: #131313;\n        margin-bottom: .6rem;\n}\n.chat-details .box .box-bottom .list[data-v-5f94b50c] {\n        color: #C4C4C4;\n        margin-bottom: .4rem;\n}\n.chat-details .box .box-bottom .list span[data-v-5f94b50c] {\n          margin-left: .6rem;\n          color: #131313;\n}\n.chat-details .box .kong[data-v-5f94b50c] {\n      width: 5.1rem;\n      height: .4rem;\n      background: rgba(255, 255, 255, 0.5);\n      position: absolute;\n      bottom: 0;\n      border-radius: .08rem;\n      left: 0.6rem;\n}\n.chat-details .box .kong1[data-v-5f94b50c] {\n      width: 5.7rem;\n      height: .3rem;\n      background: rgba(255, 255, 255, 0.5);\n      position: absolute;\n      bottom: 0.1rem;\n      border-radius: .08rem;\n      left: 0.3rem;\n}\n", ""]);
// Exports
module.exports = exports;
